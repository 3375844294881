
import 'jquery-validation';
import Inputmask from "inputmask";
import AirDatepicker from 'air-datepicker';
import localeEs from 'air-datepicker/locale/es';
import Swal from 'sweetalert2';
import select2 from 'select2';

(function ($) {
    $(function () {
        var application = new App();
        application.init();
    });

    var App = function () {
		var globals = window.globals,
            $form = $('#frm_empleo'),
            $wrapper_form = $('.form-empleo'),
            _event = 'click',
            $input = $form.find('.form-group input[type!="radio"]'),
            date = new Date(),
            oldYear = date.getFullYear()-18,
            str_date = "January 01, "+oldYear+" 23:59:59";

        var initConfig = function(){
            if($form.length){
                $('input[name="surgeryOptions"]').on('change', function(e){
                    if($(this).val() === "Si"){
                        $('.surgery_reason').removeClass('hide');
                    }else{
                        $('.surgery_reason').addClass('hide');
                    }
                });

                const phones = document.querySelectorAll('.phone');
                phones.forEach(element => {
                    Inputmask({"mask": "9999-9999"}).mask(element);
                    element.setAttribute('placeholder', "XXXX-XXXX");
                    element.setAttribute('maxlength', "9");
                });

                const numbers = document.querySelectorAll('.number');
                numbers.forEach(element => {
                    Inputmask({"mask": "99"}).mask(element);
                    element.setAttribute('placeholder', "0");
                    element.setAttribute('maxlength', "2");
                });

                // console.log(calculateAge('1988-05-25'));

                var options = {
                    locale: localeEs,
                    dateFormat: 'yyyy-MM-dd',
                    autoClose: true,
                    view: 'years',
                    maxDate: new Date()
                }
                // datework
                $('.datework').each((index, element) => {
                    new AirDatepicker(`#${element.id}`, options);
                })

                // Datepickers
                options.onSelect = function (date, formattedDate, datepicker) {
                    $('#age').val(calculateAge(date.formattedDate));
                };
                options.maxDate = new Date(oldYear, date.getMonth(), date.getDate());
                new AirDatepicker("#birthday", options);
            }

            $('select').select2();

            $('.form-empleo').find('select:not(.not_year)').select2({
                theme: "bootstrap",
                width: 'resolve',
                dropdownAutoWidth: true
            });
            // $('.form-empleo').find('select:not(.not_year)').select2();
            accordion();

            $('#id_card').on('focusout', function (e) {
                e.preventDefault();

                let dni = $(this).val(),
                    nationality = document.getElementById('nationality').selectedIndex;

                if(nationality === 1) {
                    $.ajax({
                        url: 'https://apis.gometa.org/cedulas/'+dni,
                        data: { 'key' : 'VHrlMsyLjQWaQde' },
                    })
                        .done(function(response) {
                            let person = response.results[0];
                            $('#firstname').val(person.firstname);
                            $('#lastname').val(person.lastname1);
                            $('#secondlastname').val(person.lastname2);
                        });
                }
            });
        }

        const accordion = () => {
            var allPanels = $('.accordion > dd').hide();

            $('.accordion > dt > a').click(function() {
                allPanels.slideUp();
                $(this).parent().next().slideDown();
                return false;
            });
        }

        var addFamilyMember = function(){
            $('.add_family').on(_event, function(e){
                e.preventDefault()
                var tr = $('.family_member').clone().removeAttr('class');
                tr.find('input').val('');
                tr.appendTo('.table-family');
            })
        }

        var calculateAge = function(date){
            var DOB = new Date(date);
            var millisecondsBetweenDOBAnd1970 = Date.parse(DOB);
            var millisecondsBetweenNowAnd1970 = Date.now();
            var ageInMilliseconds = millisecondsBetweenNowAnd1970-millisecondsBetweenDOBAnd1970;

            var milliseconds = ageInMilliseconds;
            var second = 1000;
            var minute = second*60;
            var hour = minute*60;
            var day = hour*24;
            var month = day*30;

            var year = day*365;

            //let the age conversion begin
            var years = Math.round(milliseconds/year);

            return years;
        }

        var addCourse = function(){
            $('.add_course').on(_event, function(e){
                e.preventDefault();
                var tr = $('.tr_courses').clone().removeAttr('class');
                tr.find('input[type="text"]').val('');
                tr.appendTo('.table-courses');
            })
        }

        const changeUploadFile = function(){
            $('.btn_subir').on('click', evt => {
                evt.target.closest('div.input-group').querySelector('.uploadfile').click();
            });

            $('.uploadfile').on('change', function (e) {
                var $this = $(this),
                    wp = $this.closest('div.input-group');

                if((e.target.value).length > 0){
                    wp.addClass('has-success').addClass('has-feedback');
                    wp.find('button').addClass('btn-success').removeClass('btn-primary').text('Agregado');
                    wp.find('input[type="text"]').addClass('is-valid').val("Agreagado");
                    wp.find('label').addClass('control-label');

                    if($this.closest('tr.wp-tr').length){
                        let next = $this.closest('tr.wp-tr').next();
                        next.removeClass('d-none');
                        next.find('.btn-red').show();
                    }
                }
            });

            $('.btn-red').on('click', function (e){
                $(this).closest('tr').addClass('d-none');
                $(this).closest('tr').find('.uploadfile').val('');
            });
        }

        var handleForm = {
            init: function () {
                //clear inputs on load
                $input.each(function () {
                    $(this).val('');
                });
                this.handlePlaceholder();
                this.handleValidation();
            },

            //smart placeholder to remove text when in focus
            handlePlaceholder: function () {
                $input.on('blur', function () {
                    if ($(this).val() === '') {
                        //add class error
                    }else{
						removeError($(this));
					}
                });
            },

            handleValidation: function () {
                $.validator.setDefaults({
                    ignore: [],
                    submitHandler: function (form) {
                        form.submit();
                    }
                });

                $.validator.addMethod('nonumber', function (value, element) {
                    return /^[a-zA-Z ,\-']+$/.test(value);
                });

                //a more strict validation for email
                $.validator.addMethod('emailvalidation', function (value, element) {
                    return /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(value);
                });

                //check if confirmemail matches with email
                $.validator.addMethod('emailmatch', function (value) {
                    if ($('#email').val() == value) {
                        return true;
                    }
                });

                $.validator.addMethod( "extension", function( value, element, param ) {
                    param = typeof param === "string" ? param.replace( /,/g, "|" ) : "png|jpe?g|gif";
                    return this.optional( element ) || value.match( new RegExp( "\\.(" + param + ")$", "i" ) );
                }, $.validator.format( "Please enter a value with a valid extension." ) );

                $form.validate({
                    debug: true,
                    ignore: "#id_card",
                    //let's validate the field on spot
                    // onfocusout: function (element) {
					// 	//except for checkboxes
                    //     if ($(element).hasClass('error')) {
                    //         $form.addClass('error');
                    //         $(element).addClass('is-invalid');
                    //     } else {
                    //         $form.removeClass('error');
                    //         $(element).removeClass('is-invalid');
                    //         $(element).addClass('is-valid');
                    //     }
                    // },
                    rules: {
                        nationality: {
                            required: true,
                        },
                        id_card: {
                            required: true,
                            minlength: 9,
                            maxlength: 50,
                        },
                        email: {
                            required: true,
                            email: true,
                            minlength: 1,
                            maxlength: 50,
                        },
                        birthday: {
                            required: true,
                        },
                        age: {
                            required: true,
                            minlength: 2,
                            maxlength: 2,
                            number: true
                        },
                        marital_status: {
                            required: true,
                        },
                        phone: {
                            minlength: 9,
                            maxlength: 9
                        },
                        mobile: {
                            required: true,
                            minlength: 9,
                            maxlength: 9
                        },
                        address: {
                            required: true,
                            minlength: 1,
                        },
                        licence: {
                            required: true,
                        },
                        scheduleOptions: {
                            required: true,
                        },
                        wristOptions: {
                            required: true,
                        },
                        backOptions: {
                            required: true,
                        },
                        surgeryOptions: {
                            required: true,
                        },
                        authorizationOptions: {
                            required: true,
                        },
                        schoolOptions: {
                            required: true,
                        },
                        highSchoolOptions: {
                            required: true,
                        },
                        universityOptions: {
                            required: true,
                        },
                        inyectionOptions: {
                            required: true,
                        },
                        plasticOptions: {
                            required: true,
                        },
                        electricityOptions: {
                            required: true,
                        },
                        hydraulicsOptions: {
                            required: true,
                        },
                        industrialOptions: {
                            required: true,
                        },
                        maintenanceOptions: {
                            required: true,
                        },
                        iso_9001_Options: {
                            required: true,
                        },
                        iso_14001_Options: {
                            required: true,
                        },
                        iso_13485_Options: {
                            required: true,
                        },
                        cv: {
                            required: true,
                            extension: "pdf|jpe?g|png|gif|doc|docx"
                        },
                        reference: {
                            required: true,
                            extension: "pdf|jpe?g|png|gif|doc|docx"
                        },
                        cedula: {
                            required: true,
                            extension: "pdf|jpe?g|png|gif|doc|docx"
                        },
                        sheetpolicy: {
                            required: true,
                            extension: "pdf|jpe?g|png|gif|doc|docx"
                        },
                    },
                    errorPlacement: function (error, element) {
                        console.log('Element with error: ', element);
                        if(element.length){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Favor llenar los campos obligatorios',
                                willClose: () => {
                                    $('html, body').animate({
                                        scrollTop: $('.form-empleo').offset().top
                                    }, 1000);
                                },
                            });

                        }
						addError($(element));

                        return true;
                    },
                    submitHandler: function (form) {
                        var frm = document.getElementById('frm_empleo');
                        var formData = new FormData(frm);
                        formData.append('action', 'employees');

                        $.ajax({
                            type: 'POST',
                            crossDomain: true,
                            dataType: 'json',
                            contentType: false,
                            cache: false,
                            processData:false,
                            url: globals.url_ajax,
                            headers: {
                                'Access-Control-Allow-Origin': '*'
                            },
                            data: formData,
                            beforeSend: function(){
                                $wrapper_form.css({
                                    'opacity': 0.5,
                                    'pointer-events': 'none'
                                });

                                $wrapper_form.find('.spinner-container').show();
                                $( "body" ).animate({ "top": $('.spinner-container').position().top+"px" }, "slow" );
                                $form.find('#error').remove();
                            },
                            success: function (data, textStatus, jqXHR) {
                                console.log(data, textStatus);
                                switch (textStatus) {
                                    case 'success':
                                        $form[0].reset();
                                        $wrapper_form.find('.spinner-container').hide();
                                        $wrapper_form.removeAttr('style');
                                        Swal.fire({
                                            position: 'center-center',
                                            icon: 'success',
                                            title: 'La información ha sido enviada correctamente',
                                            showConfirmButton: false,
                                            timer: 4000
                                        });
                                        window.location.reload();
                                        break;

                                    case 'failed':
                                        $form.removeClass('error').removeAttr('style');
                                        $form.find('.spinner-container').hide();
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'Ocurrió un error! Inténtelo de nuevo.',
                                            timer: 3000
                                        })
                                        break;
                                }
                            },
                            error: function (XMLHttpRequest, textStatus, errorThrown) {
								// console.log('ERROR', XMLHttpRequest, textStatus);
                            }
                        });
                        return false;
                    }
                });
            }
		};

		var addError = function(element){
            switch(element[0].type){
                case 'radio':
                    element.closest('div').addClass('has-error').find('label').addClass('control-label');
                    element.addClass('is-invalid');
                    break;
                case 'text':
                default:
                    // element.closest('.form-group').addClass('has-error').find('label').addClass('control-label');
                    element.addClass('is-invalid');
                    break;
            }
		}

		var removeError = function(element){
			element.closest('.form-group').removeClass('has-error').find('label').removeClass('control-label');
		}

        this.init = function () {
            initConfig();
            handleForm.init();
            addFamilyMember();
            addCourse();
            changeUploadFile();
        };
    }
}(window.$));
