import device from "current-device";

(function($){
    $(window).on('scroll', function() {

        "use strict";

        /*----------------------------------------------------*/
        /*	Navigtion Menu Scroll
        /*----------------------------------------------------*/

        const b = $(window).scrollTop();

        if( b > 72 ){
            $(".navbar").addClass("scroll");
        } else {
            $(".navbar").removeClass("scroll");
        }

    });

    /*----------------------------------------------------*/
    /*	Mobile Menu Toggle
    /*----------------------------------------------------*/

    if ( $(window).width() < 769 ) {
        $('.navbar-nav li.nav-item.nl-simple').on('click', function() {
            $('#navbarSupportedContent').css("height", "1px").removeClass("in").addClass("collapse");
            $('#navbarSupportedContent').removeClass("show");
        });
    }

    /*----------------------------------------------------*/
    /*	Hero Text Rotator
    /*----------------------------------------------------*/

    $('.hero-video-slider').flexslider({
        animation: "fade",
        controlNav: true,
        directionNav: false,
        slideshowSpeed: 5500,
        animationSpeed: 1500,
        start: function(slider){
            $('body').removeClass('loading');
        }
    });

    /*----------------------------------------------------*/
    /*	ScrollUp
    /*----------------------------------------------------*/

    $.scrollUp = function (options) {

        // Defaults
        var defaults = {
            scrollName: 'scrollUp', // Element ID
            topDistance: 600, // Distance from top before showing element (px)
            topSpeed: 800, // Speed back to top (ms)
            animation: 'fade', // Fade, slide, none
            animationInSpeed: 200, // Animation in speed (ms)
            animationOutSpeed: 200, // Animation out speed (ms)
            scrollText: '', // Text for element
            scrollImg: false, // Set true to use image
            activeOverlay: false // Set CSS color to display scrollUp active point, e.g '#00FFFF'
        };

        var o = $.extend({}, defaults, options),
            scrollId = '#' + o.scrollName;

        // Create element
        $('<a/>', {
            id: o.scrollName,
            href: '#top',
            title: o.scrollText
        }).appendTo('body');

        // If not using an image display text
        if (!o.scrollImg) {
            $(scrollId).text(o.scrollText);
        }

        // Minium CSS to make the magic happen
        $(scrollId).css({'display':'none','position': 'fixed','z-index': '2147483647'});

        // Active point overlay
        if (o.activeOverlay) {
            $("body").append("<div id='"+ o.scrollName +"-active'></div>");
            $(scrollId+"-active").css({ 'position': 'absolute', 'top': o.topDistance+'px', 'width': '100%', 'border-top': '1px dotted '+o.activeOverlay, 'z-index': '2147483647' });
        }

        // Scroll function
        $(window).on('scroll', function(){
            switch (o.animation) {
                case "fade":
                    $( ($(window).scrollTop() > o.topDistance) ? $(scrollId).fadeIn(o.animationInSpeed) : $(scrollId).fadeOut(o.animationOutSpeed) );
                    break;
                case "slide":
                    $( ($(window).scrollTop() > o.topDistance) ? $(scrollId).slideDown(o.animationInSpeed) : $(scrollId).slideUp(o.animationOutSpeed) );
                    break;
                default:
                    $( ($(window).scrollTop() > o.topDistance) ? $(scrollId).show(0) : $(scrollId).hide(0) );
            }
        });

        // To the top
        $(scrollId).on('click', function(event){
            $('html, body').animate({scrollTop:0}, o.topSpeed);
            event.preventDefault();
        });

    };

    // $.scrollUp();

    /*----------------------------------------------------*/
    /*	Portfolio Grid
    /*----------------------------------------------------*/

    $('.grid-loaded').imagesLoaded(function () {

        // filter items on button click
        $('.portfolio-filter').on('click', 'button', function () {
            var filterValue = $(this).attr('data-filter');
            $grid.isotope({
                filter: filterValue
            });
        });

        // change is-checked class on buttons
        $('.portfolio-filter button').on('click', function () {
            $('.portfolio-filter button').removeClass('is-checked');
            $(this).addClass('is-checked');
            var selector = $(this).attr('data-filter');
            $grid.isotope({
                filter: selector
            });
            return false;
        });

        // init Isotope
        var $grid = $('.masonry-wrap').isotope({
            itemSelector: '.portfolio-item',
            percentPosition: true,
            transitionDuration: '0.7s',
            masonry: {
                // use outer width of grid-sizer for columnWidth
                columnWidth: '.portfolio-item',
            }
        });

    });


    /*----------------------------------------------------*/
    /*	Single Image Lightbox
    /*----------------------------------------------------*/
    // TODO: Remove this classes image-link video-popup1 video-popup2

    /*----------------------------------------------------*/
    /*	Statistic Counter
    /*----------------------------------------------------*/

    $('.statistic-number').each(function () {
        $(this).appear(function() {
            $(this).prop('Counter',0).animate({
                Counter: $(this).text()
            }, {
                duration: 4000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
            });
        },{accX: 0, accY: 0});
    });


    /*----------------------------------------------------*/
    /*	Animated Progress Bar
    /*----------------------------------------------------*/
    var delay = 500;
    $(".progress-bar").each(function(i){
        $(this).delay( delay*i ).animate( { width: $(this).attr('aria-valuenow') + '%' }, delay );

        $(this).prop('Counter',0).animate({}, {
            duration: delay,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now)+'%');
            }
        });
    });

})(jQuery);
