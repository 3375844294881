import 'bootstrap';
import jQuery from 'jquery';
window.$ = jQuery;
import 'imagesloaded/imagesloaded.pkgd.min';
import './jquery.appear.min';
import './jquery.flexslider';


(function($){

})(jQuery)
